import React, { createContext, useContext, useState } from 'react';
import useAuth from './useAuth/useAuth';

import useMatch, { PrepareMatch, Match, Matches } from '../hooks/useMatch/useMatch';

export interface StateContextType {
    isFetching: boolean;
    jwt: string | null;
    isAuthReady?: boolean;
    signIn?(teamName: string, coachName: string, code: string, logo: string): Promise<void>;
    checkToken?(jwt: string): Promise<void>;
    getToken(name: string, room: string, passcode?: string): Promise<string>;

}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
    const [isFetching, setIsFetching] = useState(false);
    
    let contextValue = {    
        isFetching,
    } as StateContextType;
        
        contextValue = {
          ...contextValue,
          ...useAuth(),
          ...useMatch(),
          getToken: async (identity, roomName) => {
            const headers = new window.Headers();
            const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
            const params = new window.URLSearchParams({ identity, roomName });
    
            return fetch(`${endpoint}?${params}`, { headers }).then(res => { 
                return res.text();
            });
          },
        };

        const getToken: StateContextType['getToken'] = (name, room) => {
            setIsFetching(true);
            return contextValue
              .getToken(name, room)
              .then(res => {
                setIsFetching(false);
                return res;
              })
              .catch(err => {
                // setError(err);
                setIsFetching(false);
                return Promise.reject(err);
              });
          };

          

    return <StateContext.Provider value={{ ...contextValue, getToken }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
    const context = useContext(StateContext);
    if (!context) {
      throw new Error('useAppState must be used within the AppStateProvider');
    }
    return context;
  }
  