
module.exports = Object.freeze({
    API_URL: "http://localhost:3125",
    //  API_URL: "http://167.71.50.222/ff",


    // LOGIN_REDIRECT_URL: "http://localhost:3000/login",
    LOGIN_REDIRECT_URL: "http://ff-dummy.s3-website.eu-central-1.amazonaws.com/login",

    BASE_PHOTO_URL: "https://ffplayerphotos.s3.eu-central-1.amazonaws.com/",
    BASE_TEAMLOGO_URL: "https://ffteamlogos.s3.eu-central-1.amazonaws.com/",
});
