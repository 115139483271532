const uuidEmit = () => {
  // Get now time
  const n = Date.now();
  // Generate random
  const r = Math.random();
  // Stringify now time and generate additional random number
  const s = String(n) + String(~~(r * 9e4) + 1e4);
  // Form UUID and return it
  return `${s.slice(0, 8)}-${s.slice(8, 12)}-4${s.slice(12, 15)}-${[8, 9, "a", "b"][~~(r * 3)]}${s.slice(15, 18)}-${s.slice(s.length - 12)}`;
};

const setApplicationIdentifier = () => {
  localStorage.setItem("storeApplicationIdentifier", "1435773823");
};

const setCallBlockerApplicationIdentifier = () => {
  localStorage.setItem("callBlockerApplicationIdentifier", "099999999999");
};

const setEarnMoneyApplicationIdentifier = () => {
  localStorage.setItem("earnMoneyApplicationIdentifier", "088888888888");
};

const setUserIdentifier = () => {
  localStorage.setItem("visitorUserIdentifier", uuidEmit());
};

const getUserIdentifier = () => {
  return localStorage.getItem("visitorUserIdentifier");
};

const getApplicationIdentifier = () => {
  return localStorage.getItem("storeApplicationIdentifier");
};

const getCallBlockerApplicationIdentifier = () => {
  return localStorage.getItem("callBlockerApplicationIdentifier");
};

const getEarnMoneyApplicationIdentifier = () => {
  return localStorage.getItem("earnMoneyApplicationIdentifier");
};

const checkCallBlockerApplicationIdentifierIsEmpty = () => {
  return getCallBlockerApplicationIdentifier() === null;
};

const checkApplicationIdentifierIsEmpty = () => {
  return getApplicationIdentifier() === null;
};

const checkUserIdentifierIsEmpty = () => {
  return getUserIdentifier() === null;
};

const checkEarnMoneyApplicationIdentifierIsEmpty = () => {
  return getEarnMoneyApplicationIdentifier() === null;
};

const checkLocalStorage = () => {
  if (checkApplicationIdentifierIsEmpty() === true) {
    setApplicationIdentifier();
  }

  if (checkUserIdentifierIsEmpty() === true) {
    setUserIdentifier();
  }

  if (checkCallBlockerApplicationIdentifierIsEmpty() === true) {
    setCallBlockerApplicationIdentifier();
  }

  if (checkEarnMoneyApplicationIdentifierIsEmpty() === true) {
    setEarnMoneyApplicationIdentifier();
  }
};

export {
  checkLocalStorage,
  getUserIdentifier,
  getEarnMoneyApplicationIdentifier,
  getApplicationIdentifier,
  checkUserIdentifierIsEmpty,
  getCallBlockerApplicationIdentifier,
  checkApplicationIdentifierIsEmpty,
  checkEarnMoneyApplicationIdentifierIsEmpty,
};
