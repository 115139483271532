import { createMuiTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    midalphaYellow: string;
    lightalphaYellow: string;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    midalphaYellow?: string;
    lightalphaYellow?: string;
  }
}

export default createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#000',
    },
    background: {
      default: '#fafaf8',
    }
  },
  lightalphaYellow: '#fff0bf',
  midalphaYellow: '#ffe695'
});
