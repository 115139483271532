import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios'
import constants from '../../config/constants';

export default function useAuth() {
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  
  const [jwt, setJwt] = useState<string | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);
  

    const signIn = useCallback((teamName: string, coachName: string, code: string, logo: string) => {
        const userCreateRequest = {
            "teamName": teamName,
            "coachName": coachName,
            "code": code,
            "logo": logo
        }

        return axios.post(`${constants.API_URL}/user/auth`, userCreateRequest).then(function (resp) {
            
            if (resp.status === 200) {
                setJwt(resp.data.jwt);
                localStorage.setItem("ff:jwt", resp.data.jwt);
            } else {
                throw new Error("error auth ")
            }
        })

    }, []);

    useEffect(() => {
        const localJwt = localStorage.getItem("ff:jwt");
        if (localJwt != null) {
            setJwt(localJwt);
        }

        setIsAuthReady(true);
    }, []);

    const checkToken = useCallback(
        async (jwt: string) => {

            axios.get(`${constants.API_URL}/user/token`, {
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': jwt
                }
            }).then(function (resp) {
                    
                if (resp.status === 200) {
                    history.replace(location?.state?.from || { pathname: '/dashboard' });
                }
                
            }).catch(err => {
                
            })
        },
        []
      );

  return { jwt, isAuthReady, signIn, checkToken };
}
