import React, {useState, useEffect} from "react";
import AppProperty from "../../images/app-properties/appBackground.png";
import {styled, createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import "../../config/Fonts.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // zoom: "100%",
      display: "flex",
      width: "100%",
      backgroundColor: "black",
      // height: "100vh",
      [theme.breakpoints.up("sm")]: {
        width: "420px",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "black",
      },
    },
    backgroundImage: {
      margin: 0,
      padding: 0,
      width: "100vw",
      height: "100vh",
      objectFit: "cover",
      opacity: "0.25",
      [theme.breakpoints.up("sm")]: {
        width: "420px",
        objectFit: "fit",
      },
    },
    paymentContentContainer: {
      position: "absolute",
      display: "flex",
      left: 0,
      bottom: 0,
      height: 357,
      width: "100%",
      backgroundColor: "rgba(253, 253, 253, 0.82)",
      backdropFilter: "blur(20px)",
      flexDirection: "column",
      [theme.breakpoints.up("sm")]: {
        position: "absolute",
        width: "420px",
        left: "auto",
        right: "auto",
      },
    },
    titleContainer: {
      display: "flex",
      height: "44px",
      width: "100%",
      alignItems: "center",
    },
    appStoreTitle: {
      margin: 0,
      padding: 0,
      marginLeft: "20px",
      fontFamily: "SFProDisplay-Semibold",
      fontSize: 20,
    },
    cancelTitle: {
      margin: 0,
      padding: 0,
      marginLeft: "auto",
      marginRight: "20px",
      color: "#007cfe",
      fontFamily: "SFProDisplay-Regular",
      fontSize: 18,
      cursor: "pointer",
    },
    fullWidthIndicator: {
      height: 0.5,
      backgroundColor: "black",
      opacity: 0.1,
    },
    margin16Indicator: {
      height: 0.5,
      marginLeft: 16,
      backgroundColor: "black",
      opacity: 0.1,
    },
    appInformationContainer: {
      display: "flex",
      height: "75px",
      width: "100%",
      alignItems: "center",
    },
    appIconBox: {
      marginLeft: 55,
      width: 40,
      height: 40,
      backgroundColor: "#fbfbfb",
      borderRadius: 9,
      objectFit: "contain",
    },
    appDetailContainer: {
      marginLeft: 16,
      display: "flex",
      color: "white",
      flexDirection: "column",
    },
    appDetailTitle: {
      padding: 0,
      margin: 0,
      fontFamily: "SFProDisplay-Regular",
      fontSize: 13,
      color: "black",
      letterSpacing: 0.68,
    },
    appDetailSubtitle: {
      padding: 0,
      margin: 0,
      fontFamily: "SFProDisplay-Regular",
      fontSize: 13,
      color: "#74747c",
      letterSpacing: 0.68,
    },
    appStorePolicyContainer: {
      display: "flex",
      height: "75px",
      width: "100%",
      marginTop: 8,
    },
    appStorePolicyInnerContainer: {
      display: "flex",
    },
    policyTitle: {
      padding: 0,
      margin: 0,
      marginLeft: 37.5,
      marginTop: 13,
      fontFamily: "SFProDisplay-Regular",
      fontSize: 13,
      color: "#70767c",
    },
    policyDetail: {
      lineHeight: 1.23,
      letterSpacing: 0.68,
      padding: 0,
      margin: 0,
      marginLeft: 13,
      marginRight: 20,
      marginTop: 13,
      fontFamily: "SFProDisplay-Regular",
      fontSize: 13,
      color: "black",
    },
    priceContainer: {
      display: "flex",
      height: "44px",
      width: "100%",
      alignItems: "center",
    },
    priceTitle: {
      marginLeft: 111,
      fontFamily: "SFProDisplay-Regular",
      color: "black",
    },
    priceDetailTitle: {
      marginLeft: "auto",
      marginRight: 15,
      fontFamily: "SFProDisplay-Semibold",
      fontWeight: 600,
      lineHeight: "normal",
      letterSpacing: -0.4,
    },

    confirmContainer: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 25,
      backgroundColor: "#007aff",
      width: 108,
      height: 33,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 100,
      objectFit: "contain",
      [theme.breakpoints.down("sm")]: {
        marginBottom: 10,
      },
    },
    confirmButton: {
      padding: 0,
      margin: 0,
      color: "white",
      fontFamily: "SFProTextRegular",
      letterSpacing: -0.2,
      fontSize: 15,
      cursor: "pointer",
    },
    emailInputContainer: {
      margin: 0,
      padding: 0,
      marginTop: 13,
      marginLeft: 30,
      marginRight: 30,
    },
    emailText: {
      fontFamily: "SFProText-Regular",
      fontSize: 15,
      color: "black",
      lineHeight: 1.3,
      letterSpacing: -0.22,
      textAlign: "center",
    },
    inputStyle: {
      width: "100%",
      height: 36,
      backgroundColor: "rgba(118, 118, 128, 0.12)",
      border: "none",
      borderRadius: 10,
      fontFamily: "SFProText-Medium",
      paddingLeft: 20,
      fontSize: 17,
    },
  }),
);

const AppPayment = () => {
  const theme = useStyles();
  const history = useHistory();

  const [confirmSelected, setConfirmSelected] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    console.log("HİSTORY >> ", history);
    //history.location.state
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const confirmClicked = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    event.preventDefault();
    setConfirmSelected(!confirmSelected);
  };

  const cancelClicked = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    event.preventDefault();
    history.push("/apps/callrecorder");
  };

  return (
    <div className={theme.container}>
      <img src={AppProperty} className={theme.backgroundImage} />
    </div>
  );
};

export default AppPayment;
