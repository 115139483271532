import React from "react";
import ReactDOM from "react-dom";

import {CssBaseline} from "@material-ui/core";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {BrowserRouter as Router, Redirect, Route, Switch, useLocation, withRouter} from "react-router-dom";

import AppStateProvider, {useAppState} from "./state";
import "./index.css";
import theme from "./theme";

import * as serviceWorker from "./serviceWorker";

// CALL RECORDER
import AppReview from "./components/AppReview/AppReview";
import AppPayment from "./components/AppPayment/AppPayment";
import AppOnboarding from "./components/AppOnboarding/AppOnboard";
import AppOnboardPackages from "./components/AppOnboarding/AppOnboardPackages";

// CALL BLOCKER
import CallBlockerOnboard from "./components/CallBlocker/CallBlockerOnboard";
import CallBlockerPackages from "./components/CallBlocker/CallBlockerPackages";
import CallBlockerLetsStart from "./components/CallBlocker/CallBlockerLetsStart";

// EARN MONEY
import EarnMoneyReview from "./components/EarnMoney/EarnMoneyReview";
import EarnMoneyPackages from "./components/EarnMoney/EarnMoneyPackages";
import EarnMoneyOnboard from "./components/EarnMoney/EarnMoneyLetsStart";

// SECOND NUMBER
import SecondNumberLetsStart from "./components/SecondNumber/SecondNumberLetsStart";
import SecondNumberPackages from "./components/SecondNumber/SecondNumberPackages";
import SecondNumberReview from "./components/SecondNumber/SecondNumberReview";

// COLORING BOOK
import ColoringBookLetsStart from "./components/ColoringBook/ColoringBookLetsStart";
import ColoringBookPackages from "./components/ColoringBook/ColoringBookPackages";
import ColoringBookReview from "./components/ColoringBook/ColoringBookReview";

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <AppStateProvider>
        <Switch>
          <Route exact path="/apps/callblocker">
            <CallBlockerOnboard />
          </Route>
          <Route exact path="/apps/callblocker/packages">
            <CallBlockerPackages />
          </Route>
          <Route exact path="/apps/callblocker/letsstart">
            <CallBlockerLetsStart />
          </Route>

          {/* call recorder */}
          <Route exact path="/apps/callrecorder">
            <AppReview />
          </Route>
          <Route exact path="/apps/callrecorder/payment">
            <AppPayment />
          </Route>
          <Route exact path="/apps/callrecorder/onboard">
            <AppOnboarding />
          </Route>
          <Route exact path="/apps/callrecorder/onboard-packages">
            <AppOnboardPackages />
          </Route>

          {/* earn money */}
          <Route exact path="/apps/earnmoney/">
            <EarnMoneyReview />
          </Route>
          <Route exact path="/apps/earnmoney/packages">
            <EarnMoneyPackages />
          </Route>
          <Route exact path="/apps/earnmoney/letsstart">
            <EarnMoneyOnboard />
          </Route>

          {/* second number  */}
          <Route exact path="/apps/secondnumber">
            <SecondNumberReview />
          </Route>

          <Route exact path="/apps/secondnumber/packages">
            <SecondNumberPackages />
          </Route>

          <Route exact path="/apps/secondnumber/letsstart">
            <SecondNumberLetsStart />
          </Route>

          {/* coloring book */}

          <Route exact path="/apps/coloringbook">
            <ColoringBookReview />
          </Route>

          <Route exact path="/apps/coloringbook/packages">
            <ColoringBookPackages />
          </Route>

          <Route exact path="/apps/coloringbook/letsstart">
            <ColoringBookLetsStart />
          </Route>

          <Route path="/">
            <Redirect to="/apps/callrecorder" />
          </Route>
        </Switch>
      </AppStateProvider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
