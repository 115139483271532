import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'

import Promotion from '../../images/earn-money/promotion.png'
import Unlimited from '../../images/earn-money/unlimitedIcon.png'

import '../../config/AvenirFonts.css'
import '../../config/Fonts.css'
import axios from 'axios'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
/* 'GET',
                  'LETS_START',
                  'OPEN',
                  'PACKAGE1',
                  'PACKAGE2',
                  'CONFIRM',
                  'EMAIL_CONFIRM',
                  'CONTINUE_TO_PAYMENT' */

import {
    getUserIdentifier,
} from '../../config/localHelper'
import '../../config/AvenirFonts.css'


// icons
import NextArrow from '../../images/CallBlocker/next-arrow-black.png'
import AppIcon from "../../images/earn-money/earnmoney.png";


import constants from '../../config/constants';

const apiEndpoint = "https://madduck.co/abstore/events"

const AppOnboardStyle = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            // backgroundColor: "#f5f4ff",
            backgroundColor: "rgb(31,23,105,1)",
            width: "100%",
            // height: "100vh",
            height: "100%",
            overflowX: "hidden",
            overflowY: "scroll",
            [theme.breakpoints.up('sm')]: {
                // width: "420px",
                marginLeft: "auto",
                marginRight: "auto",
                height: "100vh"
            },
        },
        title: {
            margin: 0,
            padding: 0,
            fontFamily: "Avenir-Medium",
            fontSize: 28,
            textAlign: "center",
            marginTop: 40,
            color: "white"
        },
        subtitle: {
            margin: 0,
            padding: 0,
            fontFamily: "Avenir",
            marginLeft: 10,
            marginRight: 10,
            fontSize: 16,
            color: "white",
            marginTop: 18,
            textAlign: "center"
        },
        imageContainer: {
            display: "flex",
            // height: 150,
            height: 288,
            marginTop: 35,
            marginLeft: 5,
            marginRight: 5
        },
        imageInnerContainer: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
        },
        imageStyle: {
            //height: 40,
            //width: 40,
            height: 289,
            width: 50,
            alignSelf: "center",
        },
        imageStyleWithAuto: {
            //height: 40,
            //width: 40,
            height: 200,
            width: 68,
            alignSelf: "center",
            marginTop: "auto"
        },
        imageTitle: {
            margin: 0,
            padding: 0,
            textAlign: "center",
            marginTop: 8,
            color: "white",
            fontFamily: "Avenir-Book",
            lineHeight: "16pt",
            fontSize: 13
        },
        phoneImageStyle: {
            //height: 500,
            //width: "150%",
            height: 400,
            width: 300,
            //marginLeft: "-45%",
            //marginTop: -200
        },
        descriptionTextContainer: {
            marginLeft: 30,
            marginRight: 30,
            marginTop: 25
        },
        descriptionTitle: {
            margin: 0,
            padding: 0,
            color: "white",
            fontFamily: "Avenir-Book",
            lineHeight: "18pt",
            textAlign: "justify"
        },
        termsConditionContainer: {
            display: "flex",
            flexDirection: "column",
            marginTop: 40,
            justifyContent: "center",
            alignItems: "center",
        },
        termsConditionTitle: {
            margin: 0,
            padding: 0,
            color: "white",
            textDecoration: "underline",
            fontFamily: "Avenir-Roman",
            fontSize: 17,
            cursor: "pointer"
        },
        policyTitle: {
            margin: 0,
            padding: 0,
            color: "white",
            textDecoration: "underline",
            fontFamily: "Avenir-Roman",
            fontSize: 17,
            marginTop: 12,
            cursor: "pointer"
        },
        bottomContainer: {
            // height:300,
            height:217,
            backgroundColor: "rgba(16,11,60,1)",
            marginTop: 40,
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            zIndex: 100,
            [theme.breakpoints.up('sm')]: {
                position: "fixed",
                // width: "420px",
                left: "auto",
                right: "auto",
                bottom: 0
            },
        },
        emptyHeightDiv: {
            // height: 24,
            height:0,
            // backgroundColor: "#1f1769",
            backgroundColor: "rgba(16,11,60,1)",
        },
        packageOptionContainer: {
            display: "flex",
            flexDirection:"column",
            height: 69,
        },
        firstPackageOptionContainer: {
            display: "flex",
            flexDirection: "column",
            height: 80,
            width: 80,
            borderRadius: 15,
            border: "2px solid white",
            backgroundColor: "white"
        },
        secondPackageOptionContainer: {
            display: "flex",
            flexDirection: "column",
            height: 80,
            width: 80,
            borderRadius: 15,
            border: "2px solid white"
        },
        VectorIcon: {
            width: 33,
            height: 18,
            alignSelf: "center",
            marginTop: 10
        },
        packageTitle: {
            margin: 0,
            padding: 0,
            color: "#1e1e1e",
            fontFamily: "SFProTextMedium, BlinkMacSystemFont, serif",
            fontSize: 16,
            textAlign: "center",
            marginTop: 4
        },
        packageSubtitle: {
            margin: 0,
            padding: 0,
            color: "#1e1e1e",
            fontFamily: "SFProTextRegular, BlinkMacSystemFont, serif",
            textAlign: "center",
            marginTop: 2,
            fontSize: 12
        },
        continueContainer: {
            display: "flex",
            backgroundColor: "#ffaa77",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 23,
            marginRight: 23,
            borderRadius: 4,
            height: 50,
            marginTop: 22
        },
        continueTitle: {
            margin: 0,
            padding: 0,
            color: "#1e1e1e",
            fontSize: 20,
            fontFamily: "SFProDisplaySemibold, BlinkMacSystemFont, serif"
        },
        nextArrow: {
            width: 16,
            height: 15,
            marginLeft: 10,
            cursor: "pointer"
        },
        priceTextContainer: {
            display: "flex",
            height: 15,
            justifyContent: "space-around"
        },
        priceTextInnerContainer: {
            height: 20,
            width: 120,
            alignSelf: "center"
        },
        priceTextTitle: {
            margin: 0,
            padding: 0,
            textAlign: "center",
            fontFamily: "SFProTextRegular, BlinkMacSystemFont, serif",
            fontSize: 11,
            color: "rgba(255,255,255,0.5)",
            marginTop: 4
        },
        bestOfferContainer: {
            display: "none",
            height: 20,
            justifyContent: "space-around",
        },
        bestOfferContainerMargined: {
            display: "flex",
            height: 20,
            justifyContent: "space-around",
            marginTop: 12
        },
        bestOfferInnerContainer: {
            height: 20,
            width: 70,
            backgroundColor: "rgba(54,142,231,1)",
            alignSelf: "center",
            borderRadius: 8,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        bestOfferTitle: {
            margin: 0,
            padding: 0,
            textAlign: "center",
            fontFamily: "Avenir-Roman",
            fontSize: 8,
            color: "white"
        },
        bestOfferEmptyDiv: {
            height: 20,
            width: 70,
            alignSelf: "center"
        },
        firstPriceContainer: {
            height: 20,
            width: 120,
            alignSelf: "center",
            borderRadius: 8,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        secondPriceContainer: {
            height: 20,
            width: 120,
            alignSelf: "center"
        },
        priceOptionTitle: {
            margin: 0,
            padding: 0,
            textAlign: "center",
            fontFamily: "SFProTextRegular, BlinkMacSystemFont, serif",
            fontSize: 16,
            color: "white"
        },
        bottomFakeBar: {
            height: 275,
        },
        paymentContentContainer: {
            position: "fixed",
            display: "flex",
            left: 0,
            bottom: 0,
            height: 357,
            width: "100%",
            backgroundColor: "rgba(253, 253, 253, 0.82)",
            overflowY: "hidden",
            backdropFilter: "blur(20px)",
            flexDirection: "column",
            zIndex: 1222,
            [theme.breakpoints.up('sm')]: {
                // position: "absolute",
                // width: "420px",
                left: "auto",
                right: "auto",
                zIndex: 1000,
            },
        },
        titleContainer: {
            display: "flex",
            height: "44px",
            width: "100%",
            alignItems: "center"
        },
        appStoreTitle: {
            margin: 0,
            padding: 0,
            marginLeft: "20px",
            fontFamily: "SFProDisplaySemibold, BlinkMacSystemFont, serif",
            fontSize: 20,
        },
        cancelTitle: {
            margin: 0,
            padding: 0,
            marginLeft: "auto",
            marginRight: "20px",
            color: "#007cfe",
            fontFamily: "SFProDisplayRegular",
            fontSize: 18,
            cursor: "pointer"
        },
        fullWidthIndicator: {
            height: 0.5,
            backgroundColor: "black",
            opacity: 0.1
        },
        margin16Indicator: {
            height: 0.5,
            marginLeft: 16,
            backgroundColor: "black",
            opacity: 0.1
        },
        appInformationContainer: {
            display: "flex",
            height: "75px",
            width: "100%",
            alignItems: "center"
        },
        appIconBox: {
            marginLeft: 55,
            width: 40,
            height: 40,
            backgroundColor: "#fbfbfb",
            borderRadius: 9,
            objectFit: "contain"
        },
        appDetailContainer: {
            marginLeft: 16,
            display: "flex",
            color: "white",
            flexDirection: "column"
        },
        appDetailTitle: {
            padding: 0,
            margin: 0,
            fontFamily: "SFProTextRegular, BlinkMacSystemFont, serif",
            fontSize: 13,
            color: "black",
        },
        appDetailSubtitle: {
            padding: 0,
            margin: 0,
            fontFamily: "SFProTextRegular, BlinkMacSystemFont, serif",
            fontSize: 13,
            color: "#74747c",
            letterSpacing: 0.68
        },
        appStorePolicyContainer: {
            display: "flex",
            height: "75px",
            width: "100%",
            marginTop: 8
        },
        appStorePolicyInnerContainer: {
            display: "flex"
        },
        policyTitleBottom: {
            padding: 0,
            margin: 0,
            marginLeft: 37.5,
            marginTop: 13,
            fontFamily: "SFProDisplayMedium, BlinkMacSystemFont, serif",
            fontSize: 13,
            color: "#70767c",
        },
        policyDetail: {
            lineHeight: 1.23,
            letterSpacing: 0.68,
            padding: 0,
            margin: 0,
            marginLeft: 13,
            marginRight: 20,
            marginTop: 13,
            fontFamily: "SFProDisplayRegular, BlinkMacSystemFont",
            fontSize: 14,
            color: "black"
        },
        priceContainer: {
            display: "flex",
            height: "44px",
            width: "100%",
            alignItems: "center"
        },
        priceTitle: {
            marginLeft: 111,
            fontFamily: "SFProDisplayRegular, BlinkMacSystemFont, serif",
            color: "black"
        },
        priceDetailTitle: {
            marginLeft: "auto",
            marginRight: 15,
            fontFamily: "SFProDisplaySemibold, BlinkMacSystemFont, serif",
            fontWeight: 600,
            lineHeight: "normal",
            //letterSpacing: -0.40
        },

        confirmContainer: {
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 25,
            backgroundColor: "#007aff",
            width: 108,
            height: 33,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 100,
            objectFit: "contain",
            [theme.breakpoints.down('sm')]: {
                marginBottom: 10
            },

        },
        confirmButton: {
            padding: 0,
            margin: 0,
            color: "white",
            fontFamily: "SFProDisplayMedium, BlinkMacSystemFont, serif",
            fontSize: 15,
            cursor: "pointer",
        },
        emailInputContainer: {
            margin: 0,
            padding: 0,
            marginTop: 13,
            marginLeft: 30,
            marginRight: 30,
        },
        emailText: {
            fontFamily: "SFProText-Regular",
            fontSize: 15,
            color: "black",
            lineHeight: 1.3,
            letterSpacing: -0.22,
            textAlign: "center",
        },
        inputStyle: {
            width: "100%",
            height: 36,
            backgroundColor: "rgba(118, 118, 128, 0.12)",
            border: "none",
            borderRadius: 10,
            fontFamily: "SFProText-Medium",
            paddingLeft: 20,
            fontSize: 17
        },
        shadowContainer: {
            position: "fixed",
            left: 0,
            top: 0,
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 99
        }
    })
)





const EarnMoneyPackages = () => {

    const history = useHistory()
    const theme = AppOnboardStyle()
    const [selectedPackageId, setSelectedPackageId] = useState(0)
    const [selectedPackagePrice, setSelectedPackagePrice] = useState(3.99)

    const [confirmSelected, setConfirmSelected] = useState(false)
    const [packageSelected, setPackageSelected] = useState(false)
    const [emailState, setEmailState] = useState(false)
    const [alertSpawned, setAlertSpawned] = useState(false)


    const continueClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault()
        console.log("*******")
        setPackageSelected(true)


        console.log("continue has clicked")

        const payload = {
            "eventType": "CONTINUE_TO_PAYMENT",
            "userIdentifier": getUserIdentifier(),
            "appIdentifier": "088888888888",
        }

        axios.post(apiEndpoint, payload).then(response => {
            // use response later
        }).catch(err => {
            // maybe doing error handling??
        })

    }

    const packageSelection = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, packageId: number) => {
        event.preventDefault()
        console.log("selected package > ", packageId)

        let payload = {
            "eventType": "",
            "userIdentifier": getUserIdentifier(),
            "appIdentifier": "088888888888",
        }

        if (packageId === 0) {

            payload["eventType"] = "PACKAGE1"

            console.log("payload > ", payload)

            setSelectedPackagePrice(3.99)


            axios.post(apiEndpoint, payload).then(response => {

            }).catch(error => {
                // error handling
            })


        } else {

            payload["eventType"] = "PACKAGE2"

            console.log("payload > ", payload)

            setSelectedPackagePrice(3.99)

            axios.post(apiEndpoint, payload).then(response => {

            }).catch(error => {
                // error handling
            })


        }

        setSelectedPackageId(packageId)
    }

    const cancelClicked = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
        event.preventDefault()
        //history.push("/")
        setPackageSelected(false)
        setEmailState(false)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }

    const closeAlert = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
        event.preventDefault()
        setAlertSpawned(false)
    }

    const confirmClicked = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
        event.preventDefault()

        if (emailState === false) {
            // call for to email

            let payload = {
                "eventType": "CONFIRM",
                "userIdentifier": getUserIdentifier(),
                "appIdentifier": "088888888888",
            }

            axios.post(apiEndpoint, payload).then(response => {
                // use it later maybe
            })

            setEmailState(true)
        } else {

            // send email address

            let payload: any = {
                "eventType": "EMAIL_CONFIRM",
                "userIdentifier": getUserIdentifier(),
                "appIdentifier": "088888888888",
                "email": ""
            }

            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (re.test(String(email).toLowerCase()) === false) {
                setAlertSpawned(true)
                return
            }

            if (email !== "") {
                payload["email"] = email
                payload["selectedPackagePrice"] = selectedPackagePrice
            } else {
                delete payload['email'];
            }

            console.log("payload >> ", payload)

            axios.post(apiEndpoint, payload).then(response => { }).catch(err => { })
            setPackageSelected(false)
        }

    }

    const [email, setEmail] = useState("")

    return (
        <div className={theme.container}>
            <p className={theme.title}>Superior Features</p>
            <p className={theme.subtitle}>You are about to use a Earni App with superior features compared to its competitors.</p>
            <div style={{display:"flex", height:441, marginTop:200}}>
                <img src={Promotion}
                    style={{ width: "100%", marginTop: -160, height:441}}
                />
            </div>
            <div className={theme.descriptionTextContainer}>
                <p className={theme.descriptionTitle} style={{marginTop:-100}}>
                    Your payment will be charged to your iTunes Account once you confirm your purchase. Your iTunes account will be charged again when your subscription automatically renews at the end of your current subscription period unless auto-renew is turned off at least 24 hours prior to the end of the current period. Where applicable, any unused portion of a free trial period, if offered, will be forfeited when the user repurchases a subscription. You can manage or turn off auto-renew in your Apple ID Account Settings any time after purchase.
                </p>
            </div>
            <div className={theme.termsConditionContainer}>
                <p className={theme.termsConditionTitle}>Terms & Conditions</p>
                <p className={theme.policyTitle}>Privacy Policy</p>
                <p className={theme.policyTitle}>Phone Recording Law</p>
            </div>
            <div className={theme.bottomContainer}>
                <div className={theme.emptyHeightDiv}></div>
                <div 
                style={{display:"flex", flexDirection:"column", marginTop:20}}
                >
                    <div 
                        onClick={event => { packageSelection(event, 0) }}
                        style={{display:"flex", position:"relative", backgroundColor:"rgba(31,23,105,1)", height:74, marginLeft:20, marginRight:20, borderRadius:14, alignItems:"center", border:"none"}}>
                            <div style={{color:"white"}}>
                                <div style={{display:"flex", alignItems:"center"}}>
                                    <img src={Unlimited} alt="Free for 3 days" style={{width:30, height:17, marginLeft:20}} />
                                    <div style={{color:"white"}}>
                                        <p style={{margin:0, padding:0, marginLeft:19, fontFamily:"SFProDisplayMedium, BlinkMacSystemFont, serif", fontSize:17}}>Unlimited</p>
                                        <p style={{margin:0, padding:0, marginLeft:19, fontSize:13, opacity: selectedPackageId === 0 ? 1 : 0.6}}>FREE for 3 days</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{marginLeft:"auto", color:"white"}}>
                                <p style={{margin:0, padding:0, marginRight:19, textAlign:"right", fontFamily:"SFProDisplayMedium, BlinkMacSystemFont, serif",fontSize:17}}>$3.99/m</p>
                            </div>
                    </div>
                </div>
                <div className={theme.continueContainer} onClick={continueClicked} style={{marginTop:20, height:55, borderRadius:10}}>
                    <p className={theme.continueTitle}>Continue</p>
                    <img src={NextArrow} className={theme.nextArrow} />
                </div>
                <p style={{textAlign:"center", padding:0, margin:0, marginTop:9, color:"white", fontFamily: "SFProText-Regular, BlinkMacSystemFont"}}>Automatically renews with $47.99 annually</p>
            </div >
            <div className={theme.bottomFakeBar}></div>

            {packageSelected && <div className={theme.paymentContentContainer}>
                <div className={theme.titleContainer}>
                    <p className={theme.appStoreTitle}>App Store</p>
                    <p className={theme.cancelTitle} onClick={cancelClicked}>Cancel</p>
                </div>

                <div className={theme.fullWidthIndicator}></div>

                {!emailState && <div className={theme.appInformationContainer}>
                    <div className={theme.appIconBox}>
                        <img src={AppIcon} style={{ width: 40, height: 40, borderRadius: 9 }} />
                    </div>
                    <div className={theme.appDetailContainer}>
                        <p className={theme.appDetailTitle}>Monthly Subscription</p>
                        <p className={theme.appDetailSubtitle}>Earn Money</p>
                        <p className={theme.appDetailSubtitle}>Subscription</p>
                    </div>
                </div>
                }

                {!emailState && <div className={theme.margin16Indicator}></div>}

                {!emailState && <div className={theme.appStorePolicyContainer}>
                    <div className={theme.appStorePolicyInnerContainer}>
                        <p className={theme.policyTitleBottom}>POLICY</p>
                        <p className={theme.policyDetail}>You can cancel at any time from Settings > Apple ID. The plan continues until canceled.</p>
                    </div>
                </div>
                }

                {!emailState && <div className={theme.margin16Indicator}></div>}


                {!emailState && <div className={theme.priceContainer}>
                    <div className={theme.priceTitle}>Price</div>
                    <div className={theme.priceDetailTitle}>Monthly ${selectedPackagePrice}</div>
                </div>}

                {!emailState && <div className={theme.margin16Indicator}></div>}

                {emailState &&
                    <div className={theme.emailInputContainer}>
                        <p className={theme.emailText}>Thank you for participating.Our application is not yet active.Please write your e-mail address to be notified when our application is published.</p>
                        <input
                            type="email"
                            name="name"
                            placeholder="E-mail"
                            className={theme.inputStyle}
                            onChange={handleChange}
                        />
                    </div>
                }

                <div className={theme.confirmContainer}>
                    <p
                        className={theme.confirmButton}
                        onClick={(event) => confirmClicked(event)}
                    >Confirm</p>
                </div>
            </div>}

            {packageSelected && <div className={theme.shadowContainer}></div>}

            {alertSpawned && <div style={{ position: "absolute", left: 0, top: 0, width: "100vw", height: "100vh", backgroundColor: "rgba(0,0,0,0.7)", zIndex: 100000000, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <div style={{ display: "flex", width: "270px", height: "120px", backgroundColor: "rgba(255, 255, 255, 1)", borderRadius: "13px", flexDirection: "column" }}>
                    <div style={{ height: "100%", justifyContent: "center" }}>
                        <p style={{ margin: 0, padding: 0, fontFamily: "SFProText-Bold, BlinkMacSystemFont", fontWeight: 500, fontSize: "18px", marginTop: "10px", textAlign: "center" }}>ALERT</p>
                        <p style={{ margin: 0, padding: 0, textAlign: "center", fontFamily: "SFProText-Regular, BlinkMacSystemFont" }}>Please enter an email address</p>
                    </div>
                    <div style={{ backgroundColor: "#c4c4c4", height: "1px", width: "100%", marginTop: "auto" }}></div>
                    <div style={{ display: "flex", marginTop: "auto", height: 50, justifyContent: "center", alignItems: "center" }}>
                        <p
                            style={{ margin: 0, padding: 0, color: "#007aff", fontSize: "17px", fontFamily: "-apple-system, SF UI Text, Helvetica Neue, Helvetica, Arial, sans-serif;", cursor: "pointer" }}
                            onClick={(e) => closeAlert(e)}
                        >OK</p>
                    </div>
                </div>
            </div>
            }

        </div >
    )
}

export default EarnMoneyPackages