import React, {useState, useEffect} from "react";
import "../../config/Fonts.css";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

// icons
import BackIcon from "../../images/back.png";
import ShareIcon from "../../images/share.png";
import RateIcon from "../../images/stars.png";
import MessageIcon from "../../images/message.png";
import DownIcon from "../../images/down.png";
import RightIcon from "../../images/right.png";

// stars & icons
import Star1 from "../../images/1stars.png";
import Star2 from "../../images/2stars.png";
import Star3 from "../../images/3stars.png";
import Star4 from "../../images/4stars.png";
import Star5 from "../../images/5stars.png";
import OrangeStars from "../../images/orangestars.png";
import RateStars from "../../images/ratestars.png";
import Pencil from "../../images/pencil.png";
import QuestionMark from "../../images/questionmark.png";
import HandRaise from "../../images/handraise.png";
import FamilySharing from "../../images/familysharing.png";

// tab-bar icons
import AppsIcon from "../../images/tabbar/apps.png";
import ArcadeIcon from "../../images/tabbar/arcade.png";
import GamesIcon from "../../images/tabbar/games.png";
import SearchIcon from "../../images/tabbar/search.png";
import TodayIcon from "../../images/tabbar/today.png";

import AppIcon from "../../images/icons/CBook_Icon.png";
import Preview65_1 from "../../images/previews/CBook_Preview.png";

import CardScannerLogo from "../../images/APPS/CARDSCANNER-APP.png";
import DocumentScannerLogo from "../../images/APPS/DOCUMENT-SCANNER-APP.png";
import FaxLogo from "../../images/APPS/FAX-APP.png";
import InterviewRecorderLogo from "../../images/APPS/INTERVIEW-APP.png";
import VpnLogo from "../../images/APPS/VPN-APP.png";

import {Helmet} from "react-helmet";

// local helper
import {checkLocalStorage, getUserIdentifier, getEarnMoneyApplicationIdentifier} from "../../config/localHelper";

import axios from "axios";
const apiEndpoint = "https://madduck.co/abstore/events";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    //     display: -webkit-flex;
    // -webkit-flex-wrap: wrap;
    // display: flex;
    // flex-wrap: wrap;

    container: {
      display: "block",
      //display: "-webkit-flex",
      // height: "100vh",
      width: "100%",
      backgroundColor: "rgba(254,255,254,1)",
      flexDirection: "column",
      overflow: "scroll",
      [theme.breakpoints.up("xs")]: {
        // width: "420px",
        marginLeft: "auto",
        marginRight: "auto",
        overflowX: "hidden",
        overflowY: "scroll",
      },
    },
    navigationContainer: {
      display: "-webkit-flex",
      alignItems: "center",
      height: "20px",
      width: "100vw",
      marginTop: "14px",
    },
    navigationImage: {
      width: "9px",
      height: "18px",
      marginLeft: "14px",
    },
    navigationText: {
      marginLeft: "7px",
      fontSize: "17px",
      letterSpacing: "-0.4px",
      color: "#007aff",
      fontFamily: "SFProTextRegular, BlinkMacSystemFont",
      cursor: "pointer",
    },
    appInformationContainer: {
      display: "-webkit-flex",
      height: "120px",
      marginTop: "14px",
      marginLeft: "20px",
      marginRight: "20px",
    },
    appIconContainer: {
      backgroundColor: "rgba(37,55,149,1)",
      height: "100%",
      width: "118px",
      borderRadius: "20px",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${AppIcon})`,
    },
    appNameDetailContainer: {
      display: "-webkit-flex",
      flexDirection: "column",
    },

    appNameTitle: {
      fontSize: "20px",
      letterSpacing: "0.2px",
      margin: 0,
      padding: 0,
      marginLeft: "16px",
      fontFamily: "SFProDisplaySemibold, BlinkMacSystemFont",
    },

    appNameSubtitle: {
      margin: 0,
      padding: 0,
      marginLeft: "16px",
      color: "#8e8e93",
      fontSize: "13px",
      fontFamily: "SFProTextRegular, BlinkMacSystemFont",
    },
    appDownloadContainer: {
      display: "-webkit-flex",
      height: "28px",
      marginLeft: "15px",
      marginTop: "auto",
      alignItems: "center",
      // marginRight: "-25px"
    },
    appDownloadButtonContainer: {
      display: "-webkit-flex",
      width: "74px",
      height: "28px",
      backgroundColor: "#007aff",
      borderRadius: "100px",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    appGETtitle: {
      margin: 0,
      padding: 0,
      color: "white",
      fontFamily: "SFProTextBold, BlinkMacSystemFont",
    },
    appShareIconContainer: {
      display: "-webkit-flex",
      marginLeft: "auto",
      cursor: "pointer",
    },
    appShareIcon: {
      width: "18px",
      height: "23px",
    },
    appRateContainer: {
      display: "-webkit-flex",
      marginTop: "29px",
      marginLeft: "20px",
      marginRight: "20px",
      height: "50px",
    },
    appTotalRatingTitle: {
      margin: 0,
      padding: 0,
      marginTop: "auto",
      fontSize: "11px",
      color: "#b4b4b8",
      textAlign: "left",
      fontFamily: "SFProTextRegular, BlinkMacSystemFont",
    },
    appRateTitle: {
      margin: 0,
      padding: 0,
      color: "#8d8f93",
      fontSize: "22px",
      fontFamily: "SFProDisplaySemibold, BlinkMacSystemFont",
    },
    appRateIcon: {
      width: "93px",
      height: "15px",
      marginLeft: "5px",
    },
    appRateAlignedContainer: {
      display: "-webkit-flex",
      flexDirection: "column",
    },
    appRateInnerContainer: {
      display: "-webkit-flex",
      alignItems: "center",
    },
    appRateAge: {
      display: "-webkit-flex",
      marginLeft: "auto",
      flexDirection: "column",
    },
    appRateAgeTitle: {
      margin: 0,
      padding: 0,
      color: "#8d8f93",
      fontSize: "21px",
      textAlign: "center",
      fontFamily: "SFProDisplaySemibold, BlinkMacSystemFont",
    },
    ageTitle: {
      margin: 0,
      padding: 0,
      fontSize: 11,
      color: "#b4b4b8",
      textAlign: "right",
      fontFamily: "SFProTextRegular, BlinkMacSystemFont",
    },
    indicatorView: {
      height: "1px",
      marginLeft: "20px",
      marginRight: "20px",
      backgroundColor: "#c6c6c8",
      marginTop: "15px",
    },
    whatsNewTitle: {
      margin: 0,
      padding: 0,
      fontSize: "20px",
      fontFamily: "SFProDisplaySemibold, BlinkMacSystemFont",
    },
    versionHistoryTitle: {
      margin: 0,
      padding: 0,
      color: "#007aff",
      textAlign: "right",
      marginLeft: "auto",
      fontSize: "15px",
      fontFamily: "SFProDisplayRegular, BlinkMacSystemFont",
      cursor: "pointer",
    },
    whatsNewContainer: {
      marginLeft: "20px",
      marginRight: "20px",
      display: "-webkit-flex",
      marginTop: "10px",
      alignItems: "center",
    },

    versionHistoryContainer: {
      marginLeft: "20px",
      marginRight: "20px",
      display: "-webkit-flex",
      marginTop: "5px",
      alignItems: "center",
    },
    versionTitle: {
      margin: 0,
      padding: 0,
      fontSize: "14px",
      color: "#8a8a8e",
      fontFamily: "SFProTextRegular, BlinkMacSystemFont",
    },
    versionTimeTitle: {
      margin: 0,
      padding: 0,
      color: "#8a8a8e",
      textAlign: "right",
      marginLeft: "auto",
      fontSize: "15px",
      fontFamily: "SFProTextRegular, BlinkMacSystemFont",
    },
    versionDetailTitle: {
      margin: 0,
      padding: 0,
      fontFamily: "SFProTextRegular, BlinkMacSystemFont",
    },
    versionDetailContainerCollapsed: {
      backgroundColor: "white",
      // marginLeft: "20px",
      // marginRight: "20px",
      // marginTop: "14px",
      // height: "100px",
      // overflow: "hidden",
      display: "-webkit-flex",
      height: "70px",
      marginLeft: "20px",
      marginRight: "15px",
      marginTop: "14px",
    },
    versionDetailContainerNotCollapsed: {
      backgroundColor: "white",
      marginLeft: "20px",
      marginRight: "15px",
      marginTop: "14px",
      // height: "auto",
      // overflow: "hidden",
      display: "-webkit-flex",
      height: "auto",
    },
    versionHistoryCollapseContainer: {
      marginTop: 10,
      marginRight: 20,
      marginLeft: "auto",
      color: "#007aff",
      width: "36px",
    },
    versionHistoryCollapseTitle: {
      margin: 0,
      padding: 0,
      marginTop: -22,
      fontFamily: "SFProTextRegular, BlinkMacSystemFont",
      cursor: "pointer",
    },
    previewContainer: {
      marginTop: 1,
    },
    previewTitle: {
      margin: 0,
      padding: 0,
      fontWeight: "bold",
      marginLeft: 20,
      marginTop: 10,
      fontSize: 20,
      fontFamily: "SFProDisplaySemibold, BlinkMacSystemFont",
    },
    appPreviewImageContainer: {
      backgroundColor: "#f1f2f6",
      borderRadius: 18,
      marginLeft: 20,
      height: 174,
      width: "90%",
      border: "border: solid 1px rgba(0, 0, 0, 0.06);",
      marginTop: "10px",
      cursor: "pointer",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${Preview65_1})`,
      flexShrink: 0,
    },
    offersContainer: {
      display: "-webkit-flex",
      height: "15px",
      marginTop: "17px",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "45px",
      marginRight: "22px",
    },
    DownIcon: {
      width: "14px",
      height: "8px",
      marginLeft: "auto",
    },
    MessageIcon: {
      width: "16px",
      height: "15px",
      marginRight: "7px",
    },
    offerTitle: {
      margin: 0,
      padding: 0,
      fontSize: 12,
      color: "#8a8a8e",
      fontFamily: "SFProDisplayMedium, BlinkMacSystemFont",
    },
    appInformationContainerCollapsed: {
      display: "-webkit-flex",
      marginLeft: "20px",
      marginRight: "20px",
      marginTop: "10px",
      height: "56px",
    },
    appInformationContainerNonCollapsed: {
      display: "-webkit-flex",
      marginLeft: "20px",
      marginRight: "20px",
      marginTop: "10px",
      height: "auto",
    },
    appInformationTitle: {
      margin: 0,
      padding: 0,
      overflow: "hidden",
      fontFamily: "SFProTextRegular, BlinkMacSystemFont",
    },
    appInformationMoreLessContainer: {
      display: "-webkit-flex",
      marginLeft: 20,
      marginRight: 20,
      alignItems: "center",
      marginTop: -12,
      fontFamily: "SFProTextRegular, BlinkMacSystemFont",
    },
    appInformationMoreLessTitle: {
      margin: 0,
      padding: 0,
      marginLeft: "auto",
      color: "#007aff",
      cursor: "pointer",
    },
    appDeveloperContainer: {
      display: "-webkit-flex",
      marginTop: "28px",
      marginLeft: "20px",
      marginRight: "20px",
      height: "35",
    },
    appDeveloperNameTitle: {
      margin: 0,
      padding: 0,
      color: "#007aff",
      fontSize: 14,
      fontFamily: "SFProTextRegular, BlinkMacSystemFont",
      cursor: "pointer",
    },
    appDeveloperTitle: {
      margin: 0,
      padding: 0,
      color: "#8a8a8e",
      fontSize: 12,
      fontFamily: "SFProTextRegular, BlinkMacSystemFont",
    },
    rightIconContainer: {
      display: "-webkit-flex",
      marginLeft: "auto",
      alignItems: "center",
    },
    RightIcon: {
      height: 14,
      width: 8,
    },
    indicatorView34: {
      height: "1px",
      marginLeft: "20px",
      marginRight: "20px",
      backgroundColor: "#c6c6c8",
      marginTop: "34px",
    },
    ratingReviewsContainer: {
      marginLeft: 20,
      marginRight: 20,
      marginTop: 10,
    },
    ratingReviewsTitle: {
      margin: 0,
      padding: 0,
      fontWeight: "bold",
      fontSize: "20px",
      fontFamily: "SFProDisplaySemibold, BlinkMacSystemFont",
    },
    rateStarContainer: {
      display: "-webkit-flex",
      marginLeft: 20,
      marginRight: 20,
    },
    currentRateStarTitle: {
      margin: 0,
      padding: 0,
      fontWeight: "bold",
      fontSize: 55,
      color: "#4a4a4e",
      letterSpacing: 0.01,
      fontStretch: "normal",
      fontFamily: "SFProDisplaySemibold, BlinkMacSystemFont",
    },
    currentRateOutOfTitle: {
      margin: 0,
      padding: 0,
      color: "#8a8a8e",
      textAlign: "center",
      marginTop: -12,
      fontFamily: "SFProTextMedium, BlinkMacSystemFont",
    },
    starRateContainer: {
      display: "-webkit-flex",
      flexDirection: "column",
      marginLeft: "auto",
      alignItems: "center",
    },
    star5Container: {
      display: "-webkit-flex",
      marginLeft: "auto",
      backgroundColor: "white",
      marginTop: 15,
      alignItems: "center",
    },
    star5Image: {
      height: 7,
      width: 39,
    },
    star5Indicator: {
      width: "178px",
      height: 2,
      backgroundColor: "#7f7f84",
      marginLeft: 9,
    },
    star4Container: {
      display: "-webkit-flex",
      marginLeft: "auto",
      backgroundColor: "white",
      marginTop: 2,
      alignItems: "center",
    },
    star4Image: {
      height: 7,
      width: 30,
    },
    otherStarsIndicator: {
      width: "178px",
      height: 2,
      backgroundColor: "#e4e4e6",
      marginLeft: 9,
    },
    star3Image: {
      height: 7,
      width: 22,
    },
    star2Image: {
      height: 7,
      width: 14,
    },
    star1Image: {
      height: 7,
      width: 6,
    },
    otherStarsContainer: {
      display: "-webkit-flex",
      marginLeft: "auto",
      backgroundColor: "white",
      marginTop: 2,
      alignItems: "center",
    },
    ratingCountTitle: {
      margin: 0,
      padding: 0,
      marginLeft: "auto",
      textAlign: "right",
      color: "#8a8a8e",
      fontSize: "14px",
      marginTop: "10px",
      fontFamily: "SFProTextRegular, BlinkMacSystemFont",
    },
    commentContainer: {
      display: "-webkit-flex",
      marginLeft: 20,
      // marginRight: 20,
      marginRight: 5,
      marginTop: 0,
      height: 190,
      width: "90%",
      backgroundColor: "#f1f2f6",
      borderRadius: "8px",
      flexDirection: "column",
      flexShrink: 0,
    },
    commentTopContainer: {
      display: "-webkit-flex",
      height: 20,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 15,
    },
    commentTitle: {
      margin: 0,
      padding: 0,
      color: "#000002",
      fontSize: 14,
      textAlign: "left",
      fontFamily: "SFProDisplayMedium, BlinkMacSystemFont",
    },
    commentDetailTitle: {
      margin: 0,
      padding: 0,
      color: "#85858d",
      fontSize: 14,
      textAlign: "right",
      marginLeft: "auto",
      fontFamily: "SFProDisplayMedium, BlinkMacSystemFont",
    },
    commentRateContainer: {
      display: "-webkit-flex",
      height: 20,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 3,
    },
    commentStarImage: {
      marginTop: 5,
      width: 73,
      height: 12,
    },
    commentTextContainer: {
      display: "-webkit-flex",
      marginLeft: 20,
      marginRight: 20,
      marginTop: 15,
    },
    commentTextTitle: {
      margin: 0,
      padding: 0,
      lineHeight: "20px",
      letterSpacing: 0.7,
      fontSize: 14,
      fontFamily: "SFProDisplayRegular, BlinkMacSystemFont",
    },
    indicator24: {
      height: "1px",
      marginLeft: "20px",
      marginRight: "20px",
      backgroundColor: "#c6c6c8",
      marginTop: "24px",
    },
    tapToRateContainer: {
      display: "-webkit-flex",
      marginLeft: 20,
      marginRight: 20,
      height: 24,
      alignItems: "center",
      marginTop: 13,
    },
    tapToRateTitle: {
      color: "#aaaaaa",
      fontSize: 16,
      fontFamily: "SFProDisplayRegular, BlinkMacSystemFont",
    },
    tapToRateStars: {
      marginLeft: "auto",
      height: 24,
      width: "174px",
      cursor: "pointer",
    },
    writeReviewContainer: {
      display: "-webkit-flex",
      marginLeft: 20,
      marginRight: 20,
      marginTop: 24,
    },
    writeReviewTextContainer: {
      display: "-webkit-flex",
      alignItems: "center",
      cursor: "pointer",
    },

    PencilIcon: {
      height: 17,
      width: 17,
      marginRight: 6,
    },
    writeReviewTitle: {
      margin: 0,
      padding: 0,
      color: "#1a87fe",
      fontSize: 16,
      fontFamily: "SFProDisplayRegular, BlinkMacSystemFont",
    },
    appSupportContainer: {
      marginLeft: "auto",
      display: "-webkit-flex",
      alignItems: "center",
      cursor: "pointer",
    },
    questionMarkIcon: {
      height: 17,
      width: 17,
      marginRight: 6,
    },
    appSupportTitle: {
      margin: 0,
      padding: 0,
      color: "#1a87fe",
      fontSize: 16,
      fontFamily: "SFProDisplayRegular, BlinkMacSystemFont",
    },
    indicatorView29: {
      height: "1px",
      marginLeft: "20px",
      marginRight: "20px",
      backgroundColor: "#c6c6c8",
      marginTop: "29px",
    },
    informationTextContainer: {
      display: "-webkit-flex",
      marginTop: "10px",
      marginLeft: 20,
      marginRight: 20,
    },
    informationTitle: {
      padding: 0,
      margin: 0,
      fontSize: 21,
      fontFamily: "SFProDisplaySemibold, BlinkMacSystemFont",
    },

    providerContainer: {
      display: "-webkit-flex",
      height: 20,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 25,
    },

    informationTitleStyle: {
      margin: 0,
      padding: 0,
      color: "#8a8a8e",
      fontSize: 14,
      letterSpacing: "0.38px",
      fontFamily: "SFProDisplayRegular, BlinkMacSystemFont",
    },

    informationDescriptionTitle: {
      padding: 0,
      margin: 0,
      marginLeft: "auto",
      textAlign: "right",
      letterSpacing: "0.61px",
      fontSize: 14,
      fontFamily: "SFProDisplayRegular, BlinkMacSystemFont",
    },
    privacyPolicyTitle: {
      padding: 0,
      margin: 0,
      color: "#007aff",
      fontSize: 14,
      fontFamily: "SFProDisplayRegular, BlinkMacSystemFont",
      cursor: "pointer",
    },
    HandRaiseIcon: {
      width: 14,
      height: 19,
      marginLeft: "auto",
      cursor: "pointer",
    },

    indicatorView11: {
      height: "1px",
      marginLeft: "20px",
      marginRight: "20px",
      backgroundColor: "#c6c6c8",
      marginTop: "11px",
    },

    indicatorView40: {
      height: "1px",
      marginLeft: "20px",
      marginRight: "20px",
      backgroundColor: "#c6c6c8",
      marginTop: "40px",
    },

    DownIconProvider: {
      height: 8,
      width: 14,
      marginLeft: 8,
    },

    privacyPolicyContainer: {
      height: 20,
      display: "-webkit-flex",
      marginLeft: 20,
      marginRight: 20,
      alignItems: "center",
      marginTop: 15,
    },
    featureWithIcon: {
      display: "-webkit-flex",
      height: 20,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 25,
      alignItems: "center",
    },
    featureWithoutIcon: {
      display: "-webkit-flex",
      height: 20,
      marginLeft: 20,
      marginRight: 20,
      marginTop: 25,
    },
    supportsContainer: {
      display: "-webkit-flex",
      marginLeft: 20,
      marginRight: 20,
      marginTop: 10,
    },
    supportsTitle: {
      margin: 0,
      padding: 0,
      fontSize: 20,
      fontFamily: "SFProDisplaySemibold, BlinkMacSystemFont",
    },
    familySharingContainer: {
      display: "-webkit-flex",
      marginLeft: 20,
      marginRight: 20,
      marginTop: 5,
    },
    familySharingImage: {
      height: 32,
      width: 48,
      marginTop: 15,
    },
    familySharingInnerContainer: {
      display: "-webkit-flex",
      flexDirection: "column",
      marginLeft: 12,
    },
    familySharingTitle: {
      margin: 0,
      padding: 0,
      fontSize: 15,
      fontFamily: "SFProDisplayRegular, BlinkMacSystemFont",
    },
    familySharingDescriptionTitle: {
      margin: 0,
      padding: 0,
      color: "#8e8e93",
      fontSize: 12,
      fontFamily: "SFProDisplayRegular, BlinkMacSystemFont",
    },
    indicatorView10: {
      height: "1px",
      marginLeft: "90px",
      marginRight: "20px",
      backgroundColor: "#c6c6c8",
      marginTop: 10,
    },
    moreByDeveloperContainer: {
      display: "-webkit-flex",
      marginTop: 50,
      backgroundColor: "#f0f0f8",
      height: 236,
      flexDirection: "column",
    },
    moreByDeveloperTitle: {
      padding: 0,
      margin: 0,
      fontWeight: "bold",
      fontSize: 21,
      marginLeft: 20,
      marginTop: 21,
      fontFamily: "SFProDisplaySemibold, BlinkMacSystemFont",
    },
    moreByDeveloperInnerContainer: {
      display: "-webkit-flex",
      flex: 1,
    },
    moreByDeveloperFlexContainer: {
      display: "-webkit-flex",
      flex: 1,
      flexDirection: "column",
    },
    moreByDeveloperApplicationContainer: {
      display: "-webkit-flex",
      height: 62,
      marginLeft: 20,
      marginTop: 5,
    },
    moreByDeveloperApplicationContainerTop16: {
      display: "-webkit-flex",
      height: 62,
      marginLeft: 20,
      marginTop: 16,
    },
    moreByDeveloperApplicationIconContainer: {
      height: 62,
      width: 62,
      backgroundColor: "#d8d8d8",
      borderRadius: 13,
      cursor: "pointer",
    },
    moreByDeveloperAppNameTitle: {
      margin: 0,
      padding: 0,
      fontSize: 15,
      textAlign: "left",
      marginTop: 10,
      fontFamily: "SFProDisplayRegular, BlinkMacSystemFont",
      cursor: "pointer",
    },
    moreByDeveloperAppNameSubtitle: {
      margin: 0,
      padding: 0,
      color: "#84848b",
      fontSize: 12,
      textAlign: "left",
      fontFamily: "SFProDisplayRegular, BlinkMacSystemFont",
      cursor: "pointer",
    },
    moreByDeveloperAppNameContainer: {
      display: "-webkit-flex",
      flexDirection: "column",
      marginLeft: 5,
    },
    inAppPurchaseTitle: {
      padding: 0,
      margin: 0,
      fontSize: 7,
      textAlign: "center",
      marginTop: 4,
      fontFamily: "SFProTextRegular, BlinkMacSystemFont",
    },
    GETTextContainer: {
      backgroundColor: "white",
      display: "-webkit-flex",
      height: 28,
      width: 75,
      borderRadius: 100,
      justifyContent: "center",
      alignItems: "center",
    },
    GETText: {
      padding: 0,
      margin: 0,
      color: "#007aff",
      fontFamily: "SFProTextBold, BlinkMacSystemFont",
      cursor: "pointer",
    },
    applicationWithOffsetContainer: {
      width: 15,
      display: "-webkit-flex",
      flexDirection: "column",
    },
    applicationContainerOffsetFirst: {
      height: 62,
      borderTopLeftRadius: 13,
      borderBottomLeftRadius: 13,
      width: 10,
      backgroundColor: "#d8d8d8",
      marginLeft: "auto",
      marginTop: 5,
    },
    applicationContainerOffsetSecond: {
      height: 62,
      borderTopLeftRadius: 13,
      borderBottomLeftRadius: 13,
      width: 10,
      backgroundColor: "#d8d8d8",
      marginLeft: "auto",
      marginTop: 25,
    },
    getTextColumnContainer: {
      display: "-webkit-flex",
      marginLeft: "auto",
      width: 75,
      flexDirection: "column",
      cursor: "pointer",
    },
    tabBarContainer: {
      // height: "75px",
      // boxShadow: "0 -0.5px 0 0 rgba(0, 0, 0, 0.3)",
      // display: "-webkit-flex",
      // flexDirection: "column",
      height: "65px",
      boxShadow: "0 -0.5px 0 0 rgba(0, 0, 0, 0.3)",
      display: "-webkit-flex",
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      backgroundColor: "white",
      flexDirection: "column",
    },
    tabBarIconContainer: {
      height: 40,
      display: "-webkit-flex",
      justifyContent: "space-around",
      marginTop: "8px",
    },
    tabIconContainer: {
      height: 30,
      width: 30,
      marginTop: "6px",
      display: "-webkit-flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    tabTitleForIcon: {
      margin: 0,
      padding: 0,
      fontSize: 10,
      color: "#929292",
      textAlign: "center",
      marginTop: 4,
      fontFamily: "SFProTextMedium, BlinkMacSystemFont",
    },
    tabIconStyle: {
      height: 22,
      width: 22,
      alignSelf: "center",
      cursor: "pointer",
    },
    commentsDivContainer: {
      display: "-webkit-flex",
      flex: 1,
      height: 189,
      marginTop: 20,
    },
    commentDivInnerContainer: {
      display: "-webkit-flex",
      overflowX: "auto",
      overflowY: "hidden",
      marginRight: 5,
    },
  }),
);

const ColoringBookReview = () => {
  const styleClass = useStyles();
  const history = useHistory();

  const [isCollapsedVersionHistory, setCollapsedVersionHistory] = useState(true);
  const [isAboutCollapsed, setAboutCollapsed] = useState(true);
  const [circularStarted, setCircularStarted] = useState(false);
  const [applicationReviewState, setApplicationReviewState] = useState("get");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [topBarVisible, setTopBarVisible] = useState(false);

  useEffect(() => {
    async function triggerView() {
      const payload = {
        eventType: "VIEW",
        userIdentifier: getUserIdentifier(),
        appIdentifier: "066666666666",
      };

      axios
        .post(apiEndpoint, payload)
        .then((response) => {})
        .catch((error) => {});
    }

    checkLocalStorage();

    triggerView();

    const updatePosition = () => {
      console.log("position has changed > ", window.pageYOffset);

      if (window.pageYOffset > 180) {
        setTopBarVisible(true);
      } else {
        setTopBarVisible(false);
      }
    };

    window.addEventListener("scroll", updatePosition);

    updatePosition();
  }, []);

  const versionCollapseClicked = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    event.preventDefault();
    console.log("collapse clicked");
    setCollapsedVersionHistory(!isCollapsedVersionHistory);
  };

  const aboutAppCollapseClicked = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    event.preventDefault();
    console.log("collapse clicked***");
    setAboutCollapsed(!isAboutCollapsed);
  };

  const getClicked = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    event.preventDefault();

    if (applicationReviewState === "get") {
      /* 'GET',
                    'LETS_START',
                    'OPEN',
                    'PACKAGE1',
                    'PACKAGE2',
                    'CONFIRM',
                    'EMAIL_CONFIRM',
                    'CONTINUE_TO_PAYMENT' */

      const payload = {
        eventType: "GET",
        userIdentifier: getUserIdentifier(),
        appIdentifier: "066666666666",
      };
      //history.push("/apps/callblocker/packages")
      // save get call
      axios
        .post(apiEndpoint, payload)
        .then((response) => {
          setCircularStarted(true);
          setTimeout(() => {
            setCircularStarted(false);
            setApplicationReviewState("open");
          }, 1500);
        })
        .catch((error) => {
          console.log("error > ", error);
        });
    } else {
      const payload = {
        eventType: "OPEN",
        userIdentifier: getUserIdentifier(),
        appIdentifier: "066666666666",
      };

      axios
        .post(apiEndpoint, payload)
        .then((response) => {
          history.push("/apps/coloringbook/letsstart");
        })
        .catch((error) => {
          //
        });

      // save open call then go to onboar
    }
  };

  const externalAppClicked = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>, appId: Number) => {
    event.preventDefault();
    console.log("clicked app id > ", appId);
    switch (appId) {
      case 0:
        console.log("interview recorder");
        break;
      case 1:
        console.log("card scanner");
        break;
      case 2:
        console.log("doc scanner");
        break;
      case 3:
        console.log("fax application");
        break;
      case 4:
        console.log("vpn application");
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className={styleClass.container}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Coloring Book Application</title>
        </Helmet>
        {topBarVisible && (
          <div style={{display: "flex", position: "fixed", top: 0, left: 0, width: "100%", height: 50, backgroundColor: "rgba(250,248,251,1)", borderBottom: "1px solid lightgray"}}>
            <div style={{display: "flex", flex: 1, alignItems: "center"}}>
              <img src={BackIcon} className={styleClass.navigationImage} />
              <p className={styleClass.navigationText} style={{padding: 0}}>
                Apps
              </p>
            </div>
            <div style={{display: "flex", flex: 1, justifyContent: "center", alignItems: "center"}}>
              <img src={AppIcon} style={{width: 30, height: 30, borderRadius: 6}} />
            </div>
            <div style={{display: "flex", alignItems: "center", flexDirection: "row-reverse", flex: 1}}>
              <div className={styleClass.appDownloadButtonContainer} style={{marginRight: 10}}>
                {!circularStarted ? (
                  <p className={styleClass.appGETtitle} onClick={getClicked}>
                    {applicationReviewState === "get" ? "GET" : "OPEN"}
                  </p>
                ) : (
                  <CircularProgress style={{height: 20, width: 20, color: "white"}} />
                )}
              </div>
            </div>
          </div>
        )}
        <div className={styleClass.navigationContainer}>
          <img src={BackIcon} className={styleClass.navigationImage} />
          <p className={styleClass.navigationText} style={{display: "none"}}>
            Must-Have Apps
          </p>
        </div>
        <div className={styleClass.appInformationContainer}>
          <div className={styleClass.appIconContainer}></div>
          <div className={styleClass.appNameDetailContainer}>
            <p className={styleClass.appNameTitle}>
              Coloring Book - <br /> Color Everything
            </p>
            <p className={styleClass.appNameSubtitle}>Use Books with Color</p>
            <div className={styleClass.appDownloadContainer}>
              <div className={styleClass.appDownloadButtonContainer}>
                {!circularStarted ? (
                  <p className={styleClass.appGETtitle} onClick={getClicked}>
                    {applicationReviewState === "get" ? "GET" : "OPEN"}
                  </p>
                ) : (
                  <CircularProgress style={{height: 20, width: 20, color: "white"}} />
                )}
              </div>
              <div className={styleClass.appShareIconContainer} style={{display: "none"}}>
                <img src={ShareIcon} className={styleClass.appShareIcon} />
              </div>
            </div>
          </div>
          <div style={{display: "flex", flexDirection: "column", marginLeft: "auto", cursor: "pointer"}}>
            <img src={ShareIcon} className={styleClass.appShareIcon} style={{marginTop: "auto", marginBottom: 2}} />
          </div>
        </div>
        <div className={styleClass.appRateContainer}>
          <div>
            <div className={styleClass.appRateAlignedContainer}>
              <div className={styleClass.appRateInnerContainer}>
                <p className={styleClass.appRateTitle}>5,0</p>
                <img src={RateIcon} className={styleClass.appRateIcon}></img>
              </div>
              <div>
                <p className={styleClass.appTotalRatingTitle}>3 Ratings</p>
              </div>
            </div>
          </div>
          <div className={styleClass.appRateAge}>
            <p className={styleClass.appRateAgeTitle}>4+</p>
            <p className={styleClass.ageTitle}>Age</p>
          </div>
        </div>
        <div className={styleClass.indicatorView}></div>
        <div className={styleClass.whatsNewContainer}>
          <p className={styleClass.whatsNewTitle}>What's New</p>
          <p className={styleClass.versionHistoryTitle}>Version History</p>
        </div>
        <div className={styleClass.versionHistoryContainer}>
          <p className={styleClass.versionTitle}>Version 1.3.3</p>
          <p className={styleClass.versionTimeTitle}>3w ago</p>
        </div>
        <div className={isCollapsedVersionHistory ? styleClass.versionDetailContainerCollapsed : styleClass.versionDetailContainerNotCollapsed}>
          <p className={styleClass.versionDetailTitle} style={isCollapsedVersionHistory ? {overflowY: "hidden"} : {overflowY: "auto"}}>
            - New Colors & Books have added <br /> - UI/UX improvements Required enhancements for iOS 14 <br />
          </p>
        </div>
        <div className={styleClass.indicatorView} style={{marginTop: 0}}></div>
        <div className={styleClass.previewContainer}>
          <p className={styleClass.previewTitle}>Preview</p>
          <div style={{display: "flex", flexDirection: "row", overflow: "scroll"}}>
            <div className={styleClass.appPreviewImageContainer} />
          </div>
        </div>
        <div className={styleClass.offersContainer}>
          <img src={MessageIcon} className={styleClass.MessageIcon} />
          <p className={styleClass.offerTitle}>Offers Apple Watch and iMessage Apps</p>
          <img src={DownIcon} className={styleClass.DownIcon} />
        </div>
        <div className={styleClass.indicatorView}></div>
        <div className={isAboutCollapsed ? styleClass.appInformationContainerCollapsed : styleClass.appInformationContainerNonCollapsed}>
          <p className={styleClass.appInformationTitle}>
            Are you looking for a way to make color papers and books?
            <br />
            Do you want to make your consulting service more efficient?
            <br />
            Meet Earn Money Online Video Call App! With this application, you can easily serve for training and consultancy, use it as a chat & video content tool or money earning tool.
            <br />
            Create your room and start talking online. Whether you are a psychologist looking to give online psychology counseling or an online trainer looking to give diet and exercise programs, Earn
            Money Online Video Call App will be your greatest assistant to manage your customers and earn money.
            <br />
            ◉ Provide Online Consultancy Service
            <br />
            Do you provide online training or consultancy services? Good news! This application has been designed for you. Set the time range and fees of your lessons, let your students or clients
            join the room and start right away.
            <br />
            ◉ Teach Online
            <br />
            Video training or consultancy through our application real-time video call. Collect fees easily.
            <br />
            ◉ Earn Money Easily
            <br />
            Easily collect the session fees with this application.
            <br />
          </p>
        </div>
        <div className={styleClass.appInformationMoreLessContainer}>
          <p className={styleClass.appInformationMoreLessTitle} onClick={(event) => aboutAppCollapseClicked(event)}>
            {isAboutCollapsed ? "more" : "less"}
          </p>
        </div>

        <div className={styleClass.appDeveloperContainer}>
          <div>
            <p className={styleClass.appDeveloperNameTitle}>Must-Have Apps</p>
            <p className={styleClass.appDeveloperTitle}>Developer</p>
          </div>
          <div className={styleClass.rightIconContainer}>
            <img src={RightIcon} className={styleClass.RightIcon} />
          </div>
        </div>

        <div className={styleClass.indicatorView34}></div>

        <div className={styleClass.ratingReviewsContainer}>
          <p className={styleClass.ratingReviewsTitle}>Rating & Reviews</p>
        </div>

        <div className={styleClass.rateStarContainer}>
          <div>
            <p className={styleClass.currentRateStarTitle}>5,0</p>
            <p className={styleClass.currentRateOutOfTitle}>out of 5</p>
          </div>
          <div className={styleClass.starRateContainer}>
            <div className={styleClass.star5Container}>
              <img src={Star5} className={styleClass.star5Image} />
              <div className={styleClass.star5Indicator}></div>
            </div>
            <div className={styleClass.star4Container}>
              <img src={Star4} className={styleClass.star4Image} />
              <div className={styleClass.otherStarsIndicator}></div>
            </div>
            <div className={styleClass.star4Container}>
              <img src={Star3} className={styleClass.star3Image} />
              <div className={styleClass.otherStarsIndicator}></div>
            </div>
            <div className={styleClass.star4Container}>
              <img src={Star2} className={styleClass.star2Image} />
              <div className={styleClass.otherStarsIndicator}></div>
            </div>
            <div className={styleClass.star4Container}>
              <img src={Star1} className={styleClass.star1Image} />
              <div className={styleClass.otherStarsIndicator}></div>
            </div>
            <p className={styleClass.ratingCountTitle}>3 Ratings</p>
          </div>
        </div>

        <div className={styleClass.commentsDivContainer}>
          <div className={styleClass.commentDivInnerContainer}>
            <div className={styleClass.commentContainer}>
              <div className={styleClass.commentTopContainer}>
                <p className={styleClass.commentTitle}>Wonderful</p>
                <p className={styleClass.commentDetailTitle}>1m ago</p>
              </div>
              <div className={styleClass.commentRateContainer}>
                <img src={OrangeStars} className={styleClass.commentStarImage} />
                <p className={styleClass.commentDetailTitle}>Selena York</p>
              </div>
              <div className={styleClass.commentTextContainer}>
                <p className={styleClass.commentTextTitle}>It's incredible! Please add Marvel books to colorify.</p>
              </div>
            </div>

            <div className={styleClass.commentContainer} style={{display: "none"}}>
              <div className={styleClass.commentTopContainer}>
                <p className={styleClass.commentTitle}>Regular update</p>
                <p className={styleClass.commentDetailTitle}>3m ago</p>
              </div>
              <div className={styleClass.commentRateContainer}>
                <img src={OrangeStars} className={styleClass.commentStarImage} />
                <p className={styleClass.commentDetailTitle}>Stéphane A Bizier</p>
              </div>
              <div className={styleClass.commentTextContainer}>
                <p className={styleClass.commentTextTitle}>Successfully noted. I hope it continues like this.</p>
              </div>
            </div>

            <div className={styleClass.commentContainer} style={{marginRight: 20, display: "none"}}>
              <div className={styleClass.commentTopContainer}>
                <p className={styleClass.commentTitle}>I tried all of them</p>
                <p className={styleClass.commentDetailTitle}>5m ago</p>
              </div>
              <div className={styleClass.commentRateContainer}>
                <img src={OrangeStars} className={styleClass.commentStarImage} />
                <p className={styleClass.commentDetailTitle}>Lea A Endrizzi</p>
              </div>
              <div className={styleClass.commentTextContainer}>
                <p className={styleClass.commentTextTitle}>I tried all of them. This is the best call recording</p>
              </div>
            </div>
          </div>
        </div>

        <div style={{display: "-webkit-flex", flexDirection: "row", height: 22}}></div>

        <div className={styleClass.indicator24}></div>

        <div className={styleClass.tapToRateContainer}>
          <p className={styleClass.tapToRateTitle}>Tap To Rate</p>
          <img src={RateStars} className={styleClass.tapToRateStars} />
        </div>

        <div className={styleClass.writeReviewContainer}>
          <div className={styleClass.writeReviewTextContainer}>
            <img src={Pencil} className={styleClass.PencilIcon} />
            <p className={styleClass.writeReviewTitle}>Write a Review</p>
          </div>
          <div className={styleClass.appSupportContainer}>
            <img src={QuestionMark} className={styleClass.questionMarkIcon} />
            <p className={styleClass.appSupportTitle}>App Support</p>
          </div>
        </div>

        <div className={styleClass.indicatorView29}></div>

        <div className={styleClass.informationTextContainer}>
          <p className={styleClass.informationTitle}>Information</p>
        </div>

        <div className={styleClass.providerContainer}>
          <p className={styleClass.informationTitleStyle}>Provider</p>
          <p className={styleClass.informationDescriptionTitle}>Must-Have, Inc</p>
        </div>

        <div className={styleClass.indicatorView11}></div>

        <div className={styleClass.featureWithoutIcon}>
          <p className={styleClass.informationTitleStyle}>Size</p>
          <p className={styleClass.informationDescriptionTitle}>41.1 MB</p>
        </div>

        <div className={styleClass.indicatorView11}></div>

        <div className={styleClass.featureWithoutIcon}>
          <p className={styleClass.informationTitleStyle}>Category</p>
          <p className={styleClass.informationDescriptionTitle}>Business</p>
        </div>

        <div className={styleClass.indicatorView11}></div>

        <div className={styleClass.featureWithIcon}>
          <p className={styleClass.informationTitleStyle}>Compatibility</p>
          <p className={styleClass.informationDescriptionTitle}>Works on this iPhone</p>
          <img src={DownIcon} className={styleClass.DownIconProvider} />
        </div>

        <div className={styleClass.indicatorView11}></div>

        <div className={styleClass.featureWithIcon}>
          <p className={styleClass.informationTitleStyle}>Languages</p>
          <p className={styleClass.informationDescriptionTitle}>English and 12 More</p>
          <img src={DownIcon} className={styleClass.DownIconProvider} />
        </div>

        <div className={styleClass.indicatorView11}></div>

        <div className={styleClass.featureWithIcon}>
          <p className={styleClass.informationTitleStyle}>Age Rating</p>
          <p className={styleClass.informationDescriptionTitle}>4+</p>
          <img src={DownIcon} className={styleClass.DownIconProvider} />
        </div>

        <div className={styleClass.indicatorView11}></div>

        <div className={styleClass.featureWithIcon}>
          <p className={styleClass.informationTitleStyle}>In-App Purchases</p>
          <p className={styleClass.informationDescriptionTitle}>Yes</p>
          <img src={DownIcon} className={styleClass.DownIconProvider} />
        </div>

        <div className={styleClass.indicatorView11}></div>

        <div className={styleClass.featureWithoutIcon}>
          <p className={styleClass.informationTitleStyle}>Copyright</p>
          <p className={styleClass.informationDescriptionTitle}>© Must-Have, Inc</p>
        </div>

        <div className={styleClass.indicatorView11}></div>

        <div className={styleClass.privacyPolicyContainer}>
          <p className={styleClass.privacyPolicyTitle}>Privacy Policy</p>
          <img src={HandRaise} className={styleClass.HandRaiseIcon} />
        </div>

        <div className={styleClass.indicatorView40}></div>

        <div className={styleClass.supportsContainer}>
          <p className={styleClass.supportsTitle}>Supports</p>
        </div>

        <div className={styleClass.familySharingContainer}>
          <img src={FamilySharing} className={styleClass.familySharingImage} />
          <div className={styleClass.familySharingInnerContainer}>
            <p className={styleClass.familySharingTitle}>Family Sharing</p>
            <p className={styleClass.familySharingDescriptionTitle}>Up to siz family members can use this app with Family Sharing enabled. In-app purchases can’t be shared with family members.</p>
          </div>
        </div>

        <div className={styleClass.moreByDeveloperContainer} style={{display: "none"}}>
          <p className={styleClass.moreByDeveloperTitle}>More by Must-Have Apps</p>
          <div style={{display: "-webkit-flex", flex: 1, height: 190}}>
            <div style={{display: "-webkit-flex", overflowX: "auto", height: 190, flexDirection: "row"}}>
              <div style={{display: "-webkit-flex", width: 370, height: 190, flexShrink: 0}}>
                <div className={styleClass.moreByDeveloperFlexContainer}>
                  <div className={styleClass.moreByDeveloperApplicationContainer} onClick={(event) => externalAppClicked(event, 0)}>
                    <div className={styleClass.moreByDeveloperApplicationIconContainer}>
                      <img src={InterviewRecorderLogo} style={{height: "100%", width: "100%", borderRadius: 13}} />
                    </div>
                    <div className={styleClass.moreByDeveloperAppNameContainer}>
                      <p className={styleClass.moreByDeveloperAppNameTitle}>Interview Recorder</p>
                      <p className={styleClass.moreByDeveloperAppNameSubtitle}>
                        Record your interviews, <br /> meetings & calls.{" "}
                      </p>
                    </div>
                    <div className={styleClass.getTextColumnContainer}>
                      <div style={{backgroundColor: "white", display: "-webkit-flex", height: 28, width: 75, borderRadius: 100, justifyContent: "center", alignItems: "center"}}>
                        <p className={styleClass.GETText}>GET</p>
                      </div>
                      <p className={styleClass.inAppPurchaseTitle}>In App Purchases</p>
                    </div>
                  </div>
                  <div className={styleClass.indicatorView10}></div>

                  <div className={styleClass.moreByDeveloperApplicationContainerTop16} onClick={(event) => externalAppClicked(event, 1)}>
                    <div className={styleClass.moreByDeveloperApplicationIconContainer}>
                      <img src={CardScannerLogo} style={{height: "100%", width: "100%", borderRadius: 13}} />
                    </div>
                    <div className={styleClass.moreByDeveloperAppNameContainer}>
                      <p className={styleClass.moreByDeveloperAppNameTitle}>Scan Edit Sign Document</p>
                      <p className={styleClass.moreByDeveloperAppNameSubtitle}>
                        Scanning documents and <br /> sign them easily.
                      </p>
                    </div>
                    <div className={styleClass.getTextColumnContainer}>
                      <div style={{backgroundColor: "white", display: "-webkit-flex", height: 28, width: 75, borderRadius: 100, justifyContent: "center", alignItems: "center"}}>
                        <p style={{padding: 0, margin: 0, fontWeight: "bold", color: "#007aff"}}>GET</p>
                      </div>
                      <p className={styleClass.inAppPurchaseTitle}>In App Purchases</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{display: "-webkit-flex", width: 360, height: 190, flexShrink: 0}}>
                <div className={styleClass.moreByDeveloperFlexContainer}>
                  <div className={styleClass.moreByDeveloperApplicationContainer} onClick={(event) => externalAppClicked(event, 2)}>
                    <div className={styleClass.moreByDeveloperApplicationIconContainer}>
                      <img src={DocumentScannerLogo} style={{height: "100%", width: "100%", borderRadius: 13}} />
                    </div>
                    <div className={styleClass.moreByDeveloperAppNameContainer}>
                      <p className={styleClass.moreByDeveloperAppNameTitle}>Scanner Unlimited</p>
                      <p className={styleClass.moreByDeveloperAppNameSubtitle}>Scan All Documents</p>
                    </div>
                    <div className={styleClass.getTextColumnContainer}>
                      <div style={{backgroundColor: "white", display: "-webkit-flex", height: 28, width: 75, borderRadius: 100, justifyContent: "center", alignItems: "center"}}>
                        <p className={styleClass.GETText}>GET</p>
                      </div>
                      <p className={styleClass.inAppPurchaseTitle}>In App Purchases</p>
                    </div>
                  </div>
                  <div className={styleClass.indicatorView10}></div>

                  <div className={styleClass.moreByDeveloperApplicationContainerTop16} onClick={(event) => externalAppClicked(event, 3)}>
                    <div className={styleClass.moreByDeveloperApplicationIconContainer}>
                      <img src={FaxLogo} style={{height: "100%", width: "100%", borderRadius: 13}} />
                    </div>
                    <div className={styleClass.moreByDeveloperAppNameContainer}>
                      <p className={styleClass.moreByDeveloperAppNameTitle}>Fax - Scan PDF</p>
                      <p className={styleClass.moreByDeveloperAppNameSubtitle}>Send Fax Documents</p>
                    </div>
                    <div className={styleClass.getTextColumnContainer}>
                      <div style={{backgroundColor: "white", display: "-webkit-flex", height: 28, width: 75, borderRadius: 100, justifyContent: "center", alignItems: "center"}}>
                        <p style={{padding: 0, margin: 0, fontWeight: "bold", color: "#007aff"}}>GET</p>
                      </div>
                      <p className={styleClass.inAppPurchaseTitle}>In App Purchases</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{display: "-webkit-flex", width: 360, height: 190, flexShrink: 0}}>
                <div className={styleClass.moreByDeveloperFlexContainer}>
                  <div className={styleClass.moreByDeveloperApplicationContainer} onClick={(event) => externalAppClicked(event, 4)}>
                    <div className={styleClass.moreByDeveloperApplicationIconContainer}>
                      <img src={VpnLogo} style={{height: "100%", width: "100%", borderRadius: 13}} />
                    </div>
                    <div className={styleClass.moreByDeveloperAppNameContainer}>
                      <p className={styleClass.moreByDeveloperAppNameTitle}>Secret Net VPN Proxy</p>
                      <p className={styleClass.moreByDeveloperAppNameSubtitle}>Unlimited VPN</p>
                    </div>
                    <div className={styleClass.getTextColumnContainer}>
                      <div style={{backgroundColor: "white", display: "-webkit-flex", height: 28, width: 75, borderRadius: 100, justifyContent: "center", alignItems: "center"}}>
                        <p className={styleClass.GETText}>GET</p>
                      </div>
                      <p className={styleClass.inAppPurchaseTitle}>In App Purchases</p>
                    </div>
                  </div>
                  <div className={styleClass.indicatorView10} style={{visibility: "hidden"}}></div>

                  <div className={styleClass.moreByDeveloperApplicationContainerTop16} style={{visibility: "hidden"}}>
                    <div className={styleClass.moreByDeveloperApplicationIconContainer}>
                      <img src={FaxLogo} style={{height: "100%", width: "100%", borderRadius: 13}} />
                    </div>
                    <div className={styleClass.moreByDeveloperAppNameContainer}>
                      <p className={styleClass.moreByDeveloperAppNameTitle}>Call Recorder Save & Listen</p>
                      <p className={styleClass.moreByDeveloperAppNameSubtitle}>Record Phone & Voice Memos</p>
                    </div>
                    <div className={styleClass.getTextColumnContainer}>
                      <div style={{backgroundColor: "white", display: "-webkit-flex", height: 28, width: 75, borderRadius: 100, justifyContent: "center", alignItems: "center"}}>
                        <p style={{padding: 0, margin: 0, fontWeight: "bold", color: "#007aff"}}>GET</p>
                      </div>
                      <p className={styleClass.inAppPurchaseTitle}>In App Purchases</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={styleClass.moreByDeveloperInnerContainer}>
                    
                    <div className={styleClass.applicationWithOffsetContainer}>
                        <div className={styleClass.applicationContainerOffsetFirst}></div>
                        <div className={styleClass.applicationContainerOffsetSecond}></div>
                    </div>
                </div> */}
        </div>

        <div className={styleClass.tabBarContainer}>
          <div className={styleClass.tabBarIconContainer}>
            <div className={styleClass.tabIconContainer}>
              <img src={TodayIcon} className={styleClass.tabIconStyle} />
              <p className={styleClass.tabTitleForIcon}>Today</p>
            </div>
            <div className={styleClass.tabIconContainer}>
              <img src={GamesIcon} className={styleClass.tabIconStyle} />
              <p className={styleClass.tabTitleForIcon}>Games</p>
            </div>
            <div className={styleClass.tabIconContainer}>
              <img src={AppsIcon} className={styleClass.tabIconStyle} />
              <p className={styleClass.tabTitleForIcon}>Apps</p>
            </div>
            <div className={styleClass.tabIconContainer}>
              <img src={ArcadeIcon} className={styleClass.tabIconStyle} />
              <p className={styleClass.tabTitleForIcon}>Arcade</p>
            </div>
            <div className={styleClass.tabIconContainer}>
              <img src={SearchIcon} className={styleClass.tabIconStyle} />
              <p className={styleClass.tabTitleForIcon}>Search</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColoringBookReview;
