import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import constants from '../../config/constants';

export interface PrepareMatch {
    prepare_id: string;
    team_id1: string;
    team_id2: string;
    team_id1Risk: number;
    team_id2Risk: number;
    user_id1: string;
    user_id2: string;
    team1_ready: boolean;
    team2_ready: boolean;
    team_power1: number;
    team_power2: number;
    team_name1: string;
    team_name2: string;
    coach_name1: string;
    coach_name2: string;
    team_url1: string;
    team_url2: string;
}



export interface Match {
    match_id: string;
    prepare_id: string;
    team_id1: string;
    team_id2: string;
    team_id1Risk: number;
    team_id2Risk: number;
    user_id1: string;
    user_id2: string;
    team1_ready: boolean;
    team2_ready: boolean;
    events: string;
    team_id1_result: number;
    team_id2_result: number;
    team_name1: string;
    team_name2: string;
    coach_name1: string;
    coach_name2: string;
    team_url1: string;
    team_url2: string;
    team_id: string;
    created_at: string;
    CURRENT_TIMESTAMP: string;
}

export interface Matches {
    matches?: [Match]
}



export default function useMatch() {


    const [searchingMatch, setSearchingMatch] =  useState<boolean>(false);
    const [prepareMatch, setPrepareMatch] =  useState<PrepareMatch | null>(null);
    const [match, setMatch] =  useState<Match | null>(null);
    const [matches, setMatches] =  useState<Matches | null>(null);

      const findMatch = useCallback((jwt: string) => {


        return axios.post(`${constants.API_URL}/match/find`, {}, {         
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt
          }
        }).then(function (resp) {
            if(resp.status === 200) {
                setSearchingMatch(true);
               return Promise.resolve({
                    status: 'ok',
                    msg: 'find'
                })
            } else {
                return Promise.resolve({
                    status: 'not',
                    msg: 'bulamadi'
                }) 
            }
        })

    }, []);


    const findPrepareMatch = useCallback((jwt: string) => {


        return axios.post(`${constants.API_URL}/match/haspreparematch`, {}, {         
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt
          }
        }).then(function (resp) {
            if(resp.status === 200) {
               setPrepareMatch(resp.data.activeMatch)
               return Promise.resolve(resp.data.activeMatch)
            } else {
                
                return Promise.resolve({
                    status: 'not',
                    msg: 'bulamadi'
                }) 
            }
        })

    }, []);


    const chooseRisk = useCallback((jwt: string, risk: number, prepareMatchId: string) => {

        return axios.post(`${constants.API_URL}/match/risk`, {
            prepareMatchId: prepareMatchId,
            risk: risk
        }, {         
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt
          }
        }).then(function (resp) {
            if(resp.status === 200) {
            } else {

            }
        })

    }, []);


    const findPrepareMatchById = useCallback((jwt: string, prepareMatchId: string) => {

        return axios.get(`${constants.API_URL}/match/prepare/${prepareMatchId}`, {         
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt
          }
        }).then(function (resp) {
            if(resp.status === 200) {
                setPrepareMatch(resp.data.activeMatch)
                return Promise.resolve(resp.data.activeMatch)
             } else {
                 
                 return Promise.resolve({
                     status: 'not',
                     msg: 'bulamadi'
                 }) 
             }
        })

    }, []);

    const findMatchByPrepare = useCallback((jwt: string, prepareMatchId: string) => {

        return axios.post(`${constants.API_URL}/match/findmatchbyprepare`, {
            prepareMatchId: prepareMatchId
        }, {         
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt
          }
        }).then(function (resp) {
            if(resp.status === 200) {
               setMatch(resp.data.match);
               return Promise.resolve(resp.data.match )
            } else {
                return Promise.reject({
                    status: 'not',
                    msg: 'bulamadi'
                }) 
            }
        })

    }, []);

    const findMatchDetail = useCallback((jwt: string, matchId: string) => {

        return axios.get(`${constants.API_URL}/match/`+ matchId, {         
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt
          }
        }).then(function (resp) {
            if(resp.status === 200) {
               setMatch(resp.data);
               return Promise.resolve(resp.data)
            } else {
                return Promise.reject({
                    status: 'not',
                    msg: 'bulamadi'
                }) 
            }
        })

    }, []);

    const findMatches = useCallback((jwt: string) => {

        return axios.get(`${constants.API_URL}/match/`, {         
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': jwt
          }
        }).then(function (resp) {
            if(resp.status === 200) {
               setMatches(resp.data);
               return Promise.resolve(resp.data.matches)
            } else {
                return Promise.reject({
                    status: 'not',
                    msg: 'bulamadi'
                }) 
            }
        })

    }, []);



  return { matches, findMatches, searchingMatch, findMatch, findPrepareMatch, prepareMatch, chooseRisk, findMatchByPrepare, findMatchDetail, match, findPrepareMatchById };
}
