import React, {useState, useEffect} from "react";
import AssetWorldWide from "../../images/asset111.png";
import PhoneBackground from "../../images/coloringbook/cbookletsstart.png";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {checkLocalStorage, getUserIdentifier, getApplicationIdentifier} from "../../config/localHelper";
import "../../config/AvenirFonts.css";
const apiEndpoint = "https://madduck.co/abstore/events";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      zoom: "100%",
      backgroundColor: "rgba(34,55,156,1)",
      // height: "100vh",
      display: "flex",
      width: "100%",
      flexDirection: "column",
      overflowY: "scroll",
      overflowX: "hidden",
      [theme.breakpoints.up("sm")]: {
        // width: "420px",
        marginLeft: "auto",
        marginRight: "auto",
        height: "100vh",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        height: "100vh",
        // height: "100%",
      },
    },
    titleContainer: {
      display: "flex",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 40,
    },
    titleText: {
      margin: 0,
      padding: 0,
      color: "white",
      textAlign: "center",
      fontSize: 18,
      fontFamily: "Avenir-Black",
    },
    editorChoiceAssetContainer: {
      display: "flex",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 18,
    },
    editorChoiceImage: {
      height: 95,
      width: 300,
    },
    phoneBackgroundContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginTop: 15,
      height: 390,
      width: "100%",
    },
    phoneBackgroundImage: {
      height: "100%",
      width: "auto",
      marginTop: 18,
      alignSelf: "center",
    },
    descriptionContainer: {
      justifyContent: "center",
      display: "flex",
      marginTop: 30,
    },
    descriptionTitle: {
      margin: 0,
      padding: 0,
      color: "white",
      fontSize: 17,
      fontFamily: "Avenir-Roman",
    },
    buttonContainer: {
      height: 130,
    },
    letsStartContainer: {
      backgroundColor: "rgba(45,182,92,1)",
      borderRadius: 4,
      marginTop: 15,
      marginLeft: 24,
      marginRight: 24,
      height: 48,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    letsStartTitle: {
      margin: 0,
      padding: 0,
      color: "white",
      fontSize: 21,
      fontWeight: "bold",
      fontFamily: "Avenir-Black",
    },
    footer: {
      backgroundColor: "rgba(34,55,156,1)",
      width: "100%",
      display: "flex",
      // marginTop: 60,
      marginTop: 130,
    },
  }),
);

const ColoringBookLetsStart = () => {
  const styleTheme = useStyles();
  const history = useHistory();

  useEffect(() => {
    checkLocalStorage();
  }, []);

  const openPackagesPage = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    event.preventDefault();

    const payload = {
      eventType: "LETS_START",
      userIdentifier: getUserIdentifier(),
      appIdentifier: "066666666666",
    };

    axios
      .post(apiEndpoint, payload)
      .then((response) => {
        history.push("/apps/coloringbook/packages");
      })
      .catch((error) => {
        // error handling
      });

    /* 'GET',
					'LETS_START',
					'OPEN',
                    'PACKAGE1',
                    'PACKAGE2',
                    'CONFIRM',
					'EMAIL_CONFIRM',
                    'CONTINUE_TO_PAYMENT' */
  };

  return (
    <>
      <div className={styleTheme.container}>
        <div className={styleTheme.titleContainer}>
          <p className={styleTheme.titleText}>
            The Best Coloring Book App
            <br />
            Ever Designed For iPhone
          </p>
        </div>
        <div className={styleTheme.editorChoiceAssetContainer}>
          <img src={AssetWorldWide} className={styleTheme.editorChoiceImage} />
        </div>
        <div className={styleTheme.phoneBackgroundContainer}>
          <img src={PhoneBackground} className={styleTheme.phoneBackgroundImage} />
        </div>
        <div className={styleTheme.descriptionContainer}>
          <p className={styleTheme.descriptionTitle}>
            CBook is the original and best <br /> coloring book for adults!
          </p>
        </div>

        <div style={{position: "fixed", bottom: 0, left: 0, backgroundColor: "rgba(34,55,156,1)", height: "100px", width: "100%", borderTopLeftRadius: 30, borderTopRightRadius: 30}}>
          <div className={styleTheme.letsStartContainer} onClick={openPackagesPage} style={{marginTop: 30}}>
            <p className={styleTheme.letsStartTitle}>LET'S START</p>
          </div>
        </div>
        <div style={{backgroundColor: "rgba(34,55,156,1)", height: 100, marginTop: 10}}></div>
        <div className={styleTheme.footer}></div>
      </div>
    </>
  );
};

export default ColoringBookLetsStart;
